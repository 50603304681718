import React from "react";
import style from "./__search.module.scss";

interface Props {
  onClick: () => void;
}
export default function Search({onClick}: Props) {
  return (
    <div className={style.search} onClick={onClick}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.974 12.8475L17.5432 16.4158L16.364 17.595L12.7957 14.0258C11.468 15.0902 9.81654 15.6691 8.11487 15.6667C3.97487 15.6667 0.614868 12.3067 0.614868 8.16667C0.614868 4.02667 3.97487 0.666668 8.11487 0.666668C12.2549 0.666668 15.6149 4.02667 15.6149 8.16667C15.6173 9.86834 15.0384 11.5198 13.974 12.8475ZM12.3024 12.2292C13.36 11.1416 13.9506 9.68369 13.9482 8.16667C13.9482 4.94333 11.3374 2.33333 8.11487 2.33333C4.89153 2.33333 2.28153 4.94333 2.28153 8.16667C2.28153 11.3892 4.89153 14 8.11487 14C9.63189 14.0024 11.0898 13.4118 12.1774 12.3542L12.3024 12.2292Z"
          fill="#1A1A1A"
        />
      </svg>
    </div>
  );
}

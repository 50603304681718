import {useState, useEffect} from "react";
import style from "./__liked.module.scss";
import {OrderByDirection, collection, getDocs} from "firebase/firestore";

import {db} from "../../firebase";
import {doc, getDoc, query, orderBy} from "firebase/firestore";
import {useNavigate, NavLink} from "react-router-dom";
import Cookies from "universal-cookie";
import {useAuth} from "hooks/use-auth";
import ArrowUp from "./arrowUp.webp";
import ArrowDown from "./arrowDown.webp";
import LikedCard from "components/smart/LikedCard/LikedCard";
import {useAppSelector} from "hooks/redux-hooks";

import SorryImage from "../../assets/images/sorry.webp";
interface Props {
  userID: string;
}

interface Car {
  id: string;
  userId: string;
  index: string;
  brand: string;
  model: string;
  price: string;
  fuel: string;
  location: string;
  vehicleType: string;
  year: number;
  owners: string;
  description: string;
  mileage: number;
  imageUrl: string;
}

export default function Liked({userID}: Props) {
  const [likedCars, setLikedCars] = useState<string[]>([]);
  const [cars, setCars] = useState<Car[]>([]);
  const [totalCars, setTotalCars] = useState<number>(0);
  const [loadingLiked, setLoadingLiked] = useState(true);
  const [sortBy, setSortBy] = useState<string>("brand");
  const {isAuth, displayName} = useAuth();
  const [sortSetting, setSortSetting] = useState<string | undefined>("asc");
  const [loading, setLoading] = useState(true);

  const userMobile = useAppSelector((state) => state.user.mobile);
  const itemsPerPage: number = 4;
  const pages = Math.ceil(totalCars / itemsPerPage);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();

  useEffect(() => {
    const cookies = new Cookies(null, {path: "/"});
    if (!cookies.get("auth")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchLiked = async () => {
      const likedRef = collection(db, "likedCars");
      const likedDocRef = doc(likedRef, userID);
      try {
        const likedDocSnap = await getDoc(likedDocRef);
        if (likedDocSnap.exists()) {
          const likedData = likedDocSnap.data();
          const currentLikedCars = likedData?.likedCars || [];
          setLikedCars(currentLikedCars);
        }
      } catch (error) {
        console.error("Ошибка при скачивании документа: ", error);
      }
    };
    const fetchData = async () => {
      if (userID) {
        await fetchLiked();
        setLoadingLiked(false);
      }
    };

    fetchData();
  }, [userID]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuth && displayName) {
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuth, displayName, loading]);

  useEffect(() => {
    const fetchLikedCarsData = async (likedCars: string[]) => {
      try {
        const otherCarsRef = collection(db, "cars");

        let first = query(otherCarsRef);
        first = query(first, orderBy(sortBy, sortSetting as OrderByDirection));
        const querySnapshot = await getDocs(first);
        const carsData: Car[] = querySnapshot.docs
          .map((doc) => {
            const carData = doc.data() as Car;

            if (likedCars.includes(carData.id)) {
              return carData;
            }
            return null;
          })
          .filter((car) => car !== null) as Car[];

        setTotalCars(carsData.length);
        const paginatedCars = carsData.slice(
          currentPage * itemsPerPage - itemsPerPage,
          currentPage * itemsPerPage
        );

        setCars(paginatedCars);
      } catch (error) {
        console.error("Error fetching liked cars data: ", error);
      }
    };
    const fetchData = async () => {
      if (!loadingLiked) {
        fetchLikedCarsData(likedCars);
      }
    };

    fetchData();
  }, [likedCars, loadingLiked, sortBy, sortSetting, currentPage]);

  const handleSortBy = (value: string) => {
    if (sortBy === value) {
      if (sortSetting === "asc") {
        setSortSetting("desc");
      } else {
        setSortSetting("asc");
      }
    } else {
      setSortBy(value);
    }
  };

  const nextNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const prevPrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const changePage = (index: number) => {
    setCurrentPage(index + 1);
    const catalog = document.getElementById("likedCatalog");
    if (catalog) {
      catalog.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <div className={style.liked} id="likedCatalog">
      <div className={style.header}>
        <h1>Cars you liked</h1>

        <p>
          {userID &&
            (displayName
              ? displayName.charAt(0).toLocaleUpperCase() + displayName.slice(1)
              : userMobile)}
          , welcome to your liked page, this shows the cars you've liked.
        </p>
        {cars.length > 0 ? (
          <div className={style.sorting}>
            <span>Sort by:</span>
            <button
              onClick={() => handleSortBy("dateAdded")}
              className={sortBy === "dateAdded" ? style.checked : ""}
            >
              Date
              {sortBy === "dateAdded" &&
                (sortSetting === "asc" ? (
                  <img src={ArrowUp} alt="arrowUp" className={style.icon} />
                ) : (
                  <img src={ArrowDown} alt="arrowDown" className={style.icon} />
                ))}
            </button>
            <button
              onClick={() => handleSortBy("price")}
              className={sortBy === "price" ? style.checked : ""}
            >
              Price
              {sortBy === "price" &&
                (sortSetting === "asc" ? (
                  <img src={ArrowUp} alt="arrowUp" className={style.icon} />
                ) : (
                  <img src={ArrowDown} alt="arrowDown" className={style.icon} />
                ))}
            </button>
            <button
              onClick={() => handleSortBy("brand")}
              className={sortBy === "brand" ? style.checked : ""}
            >
              Brand
              {sortBy === "brand" &&
                (sortSetting === "asc" ? (
                  <img src={ArrowUp} alt="arrowUp" className={style.icon} />
                ) : (
                  <img src={ArrowDown} alt="arrowDown" className={style.icon} />
                ))}
            </button>
          </div>
        ) : (
          <div className={style.emptyPage}>
            <h5>
              You haven't liked any cars yet, go to our{" "}
              <NavLink to="/catalog">catalog</NavLink> and choose your favorite
              cars for yourself
            </h5>
            <img src={SorryImage} alt="sorry" />
          </div>
        )}
      </div>
      {likedCars && (
        <div className={style.wrapper}>
          {likedCars &&
            cars.map((car: any, index: any) => (
              <NavLink to={`/details/${car.id}`}>
                <LikedCard
                  key={`littleCard_ ` + index}
                  id={car.id}
                  index={index}
                  brand={car.brand}
                  model={car.model}
                  price={car.price}
                  year={car.year}
                  fuel={car.fuel}
                  owners={car.owners}
                  location={car.location}
                  mileage={car.mileage}
                  description={car.description}
                  previewIMG={car.previewImage[0]}
                />
              </NavLink>
              // />
            ))}
        </div>
      )}
      {likedCars.length > 0 && (
        <div className={style.buttons}>
          {likedCars.length > 4 && (
            <button onClick={prevPrevPage} disabled={currentPage === 1}>
              Previous
            </button>
          )}

          {[...new Array(pages)].map((_, index) => (
            <span
              key={index}
              className={index + 1 === currentPage ? style.currentPage : ""}
              onClick={() => changePage(index)}
            >
              {index + 1}
            </span>
          ))}
          {likedCars.length > 4 && (
            <button onClick={nextNextPage} disabled={currentPage === pages}>
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
}

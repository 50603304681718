import React from "react";
import style from "./__liked.module.scss";
export default function Liked() {
  return (
    <div className={style.liked}>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.9491 1.77417C9.90123 0.921833 11.1437 0.466556 12.4211 0.501894C13.6985 0.537231 14.9139 1.0605 15.8174 1.96417C16.7202 2.86682 17.2435 4.08062 17.2801 5.35674C17.3167 6.63286 16.8637 7.87463 16.0141 8.8275L8.94743 15.9042L1.88243 8.8275C1.03181 7.87413 0.57843 6.6313 0.615374 5.35415C0.652318 4.07701 1.17678 2.86246 2.08109 1.95986C2.9854 1.05725 4.20093 0.535086 5.47815 0.500554C6.75536 0.466022 7.99734 0.921746 8.9491 1.77417ZM14.6374 3.14167C14.0354 2.5401 13.226 2.19173 12.3753 2.16802C11.5246 2.14431 10.697 2.44707 10.0624 3.01417L8.94993 4.0125L7.8366 3.015C7.20163 2.44722 6.37335 2.14414 5.52189 2.16801C4.67043 2.19187 3.86043 2.54088 3.25826 3.14333C2.66181 3.73975 2.31364 4.54012 2.28395 5.38308C2.25426 6.22605 2.54525 7.04892 3.09826 7.68583L8.94826 13.545L14.7983 7.68667C15.3519 7.0495 15.6432 6.22603 15.6133 5.38248C15.5835 4.53892 15.2347 3.7381 14.6374 3.14167Z"
          fill="#1A1A1A"
        />
      </svg>
    </div>
  );
}

import React from "react";
import style from "./__profile.module.scss";

export default function Profile() {
  return (
    <div className={style.profile}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.9482 17.3333C4.3457 17.3333 0.614868 13.6025 0.614868 9C0.614868 4.3975 4.3457 0.666668 8.9482 0.666668C13.5507 0.666668 17.2815 4.3975 17.2815 9C17.2815 13.6025 13.5507 17.3333 8.9482 17.3333ZM4.79237 14.2133C5.97199 15.1564 7.43793 15.6691 8.9482 15.6667C10.5251 15.6689 12.0514 15.1101 13.254 14.09C12.7113 13.5331 12.0624 13.0907 11.3457 12.789C10.6291 12.4873 9.85914 12.3323 9.08153 12.3333C8.27536 12.3324 7.47779 12.499 6.73942 12.8227C6.00106 13.1463 5.33802 13.6199 4.79237 14.2133ZM3.6282 13.0167C4.32858 12.2734 5.17376 11.6814 6.11165 11.2773C7.04954 10.8731 8.06027 10.6653 9.08153 10.6667C10.0662 10.6654 11.0415 10.8586 11.9513 11.2353C12.8611 11.612 13.6876 12.1647 14.3832 12.8617C15.0966 11.8576 15.5179 10.6755 15.6003 9.4466C15.6828 8.21766 15.4232 6.98991 14.8503 5.89955C14.2774 4.80919 13.4136 3.89885 12.3548 3.26954C11.296 2.64022 10.0836 2.31653 8.85204 2.33438C7.62047 2.35222 6.41792 2.71091 5.3778 3.37063C4.33768 4.03036 3.50067 4.96534 2.95961 6.07184C2.41855 7.17833 2.19461 8.41309 2.31263 9.63913C2.43065 10.8652 2.88601 12.0345 3.6282 13.0175V13.0167ZM8.9482 9.83333C8.06415 9.83333 7.2163 9.48215 6.59118 8.85702C5.96606 8.2319 5.61487 7.38406 5.61487 6.5C5.61487 5.61595 5.96606 4.7681 6.59118 4.14298C7.2163 3.51786 8.06415 3.16667 8.9482 3.16667C9.83226 3.16667 10.6801 3.51786 11.3052 4.14298C11.9303 4.7681 12.2815 5.61595 12.2815 6.5C12.2815 7.38406 11.9303 8.2319 11.3052 8.85702C10.6801 9.48215 9.83226 9.83333 8.9482 9.83333ZM8.9482 8.16667C9.39023 8.16667 9.81415 7.99107 10.1267 7.67851C10.4393 7.36595 10.6149 6.94203 10.6149 6.5C10.6149 6.05797 10.4393 5.63405 10.1267 5.32149C9.81415 5.00893 9.39023 4.83333 8.9482 4.83333C8.50617 4.83333 8.08225 5.00893 7.76969 5.32149C7.45713 5.63405 7.28153 6.05797 7.28153 6.5C7.28153 6.94203 7.45713 7.36595 7.76969 7.67851C8.08225 7.99107 8.50617 8.16667 8.9482 8.16667Z"
          fill="#1A1A1A"
        />
      </svg>
    </div>
  );
}
